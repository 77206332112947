import React from 'react';
import { useFetchKittingUsersApi } from '@/api/kitting';
import { useKittingListHelper } from '@/pages/KittingUserList/helper';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Button, Form, Input, Table } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';

export const KittingUserList: React.FC = () => {
  const state = useKittingListHelper();
  const fetchApi = useFetchKittingUsersApi();

  React.useEffect(() => {
    fetchApi().then((res) => {
      state.dataSource.setList(res.map((a) => ({ ...a, key: a.id })));
    });
    // eslint-disable-next-line
  }, []);
  return (
    <AdminLayout>
      <h1>キッティングユーザー</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='KittingUser' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Password' name='password'>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

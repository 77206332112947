import React from 'react';
import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { Company, CreateCompanyRequest } from '@/typings/entity/company';
import { useCreateCompanyApi, useUpdateCompanyApi } from '@/api/company';
import { Link } from 'react-router-dom';

export function useCompanyListHelper() {
  const editDrawer = useEditDrawerState<Company>('id', [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (_: string, record: Company) => <Link to={`/companies/${record.id}/brands`}>{record.name}</Link>,
    },
  ]);
  const dataSource = useTableDataSource<Company>();

  const createApi = useCreateCompanyApi();
  const updateApi = useUpdateCompanyApi();
  const onSubmit = (values: Store) => {
    const { name } = values;
    const payload: CreateCompanyRequest | Company = {
      name,
    };
    if (editDrawer.mode === 'create') {
      createApi(payload).then((res) => {
        dataSource.push(res);
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(next).then(() => {
        dataSource.replace('id', next);
        editDrawer.close();
      });
    }
  };

  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

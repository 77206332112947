import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams } from 'react-router-dom';
import { useFetchCategoriesApi } from '@/api/brand';
import { useCategoryListHelper } from './helper';

export const CategoryList: React.FC = () => {
  const params = useParams<{ id: string; categoryId: string }>();
  const brandId = parseInt(params.id, 10);
  const state = useCategoryListHelper(brandId);
  const fetch = useFetchCategoriesApi();
  React.useEffect(() => {
    fetch(brandId).then((res) => {
      state.dataSource.setList(res.map((r) => ({ ...r, key: r.id })));
    });
    // eslint-disable-next-line
  }, [brandId]);
  return (
    <AdminLayout>
      <h1>商品カテゴリ</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='Category' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Priority' name='priority'>
            <Input type='number' placeholder='Priority' min={0} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

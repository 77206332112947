import React from 'react';
import moment from 'moment';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, DatePicker, TimePicker } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams, useLocation } from 'react-router-dom';
import { useFetchRestaurantTemporaryOpeningTimesApi } from '@/api/brand';
import { useTemporaryOpeningTimeHelper } from './helper';
import * as styles from './styles';

export const TemporaryOpeningTimes: React.FC = () => {
  const params = useParams<{ id: string; restaurantId: string }>();
  const brandId = parseInt(params.id, 10);
  const restaurantId = parseInt(params.restaurantId, 10);
  const state = useTemporaryOpeningTimeHelper(brandId, restaurantId);
  const query = new URLSearchParams(useLocation().search);
  const fetchTimetables = useFetchRestaurantTemporaryOpeningTimesApi();
  let year = parseInt(query.get('year') || '', 10);
  let month = parseInt(query.get('month') || '', 10);
  if (Number.isNaN(year) || Number.isNaN(month)) {
    const today = new Date();
    year = today.getFullYear();
    month = today.getMonth() + 1;
  }
  const reloadTimetables = () => {
    fetchTimetables(brandId, restaurantId, year, month).then((openingTimeResponse) => {
      state.dataSource.setList(openingTimeResponse.map((r) => ({ ...r, key: r.date + r.startAt })));
    });
  };
  state.setReload(reloadTimetables);
  const onDateChange = (_date: moment.Moment | null, dateString: string) => {
    const date = new Date(dateString);
    year = date.getFullYear();
    month = date.getMonth() + 1;
    reloadTimetables();
  };
  React.useEffect(() => {
    reloadTimetables();
    // eslint-disable-next-line
  }, [brandId]);

  return (
    <AdminLayout>
      <h1>臨時営業時間設定</h1>
      <p>指定日の営業時間を上書きします。休日にする場合は開始時間と終了時間を同じ時間に指定してください。</p>
      <div>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
        <DatePicker
          onChange={onDateChange}
          picker='month'
          defaultValue={moment([year, month - 1])}
          style={styles.picker}
        />
      </div>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='Temporary Opening Time' state={state.editDrawer.drawer}>
        <Form
          form={state.editDrawer.form}
          onFinish={state.onSubmit}
          initialValues={{
            datePicker: moment(),
            startAtPicker: moment('13:00', 'HH:mm'),
            endAtPicker: moment('15:00', 'HH:mm'),
          }}
        >
          <Form.Item label='Date' name='datePicker'>
            <DatePicker />
          </Form.Item>
          <Form.Item label='StartAt' name='startAtPicker'>
            <TimePicker format='HH:mm' />
          </Form.Item>
          <Form.Item label='EndAt' name='endAtPicker'>
            <TimePicker format='HH:mm' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

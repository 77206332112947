import { useAxiosClient } from '@/helpers/axios';
import {
  FetchPrinterConnectedStatusListResponse,
  FetchPrinterConnectedStatusListRequest,
} from '@/typings/entity/printerConnectedStatus';

export function useFetchPrinterConnectedStatusListApi() {
  const axios = useAxiosClient();
  return async (params: FetchPrinterConnectedStatusListRequest) => {
    const filters: string[] = [];
    params.filters.forEach((f) => {
      filters.push(`${f.field}=${f.values.join(',')}`);
    });
    const sorters: string[] = [];
    params.sorters.forEach((s) => {
      sorters.push(`${s.field}=${s.order}`);
    });
    return axios
      .get<FetchPrinterConnectedStatusListResponse>('/printers/connected_statuses', {
        params: {
          perPage: params.perPage,
          page: params.page,
          filter: filters,
          sorter: sorters,
        },
      })
      .then((res) => res.data);
  };
}

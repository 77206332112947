import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input, Switch } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams } from 'react-router-dom';
import { useFetchItemsApi } from '@/api/brand';
import { useItemListHelper } from './helper';

export const ItemList: React.FC = () => {
  const params = useParams<{ id: string; categoryId: string }>();
  const brandId = parseInt(params.id, 10);
  const categoryId = parseInt(params.categoryId, 10);
  const state = useItemListHelper(brandId, categoryId);
  const fetchItems = useFetchItemsApi();
  React.useEffect(() => {
    fetchItems(brandId).then((res) => {
      state.dataSource.setList(res.filter((i) => i.categoryId === categoryId).map((r) => ({ ...r, key: r.id })));
    });
    // eslint-disable-next-line
  }, [brandId]);
  return (
    <AdminLayout>
      <h1>商品</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='Item' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='UnitPrice' name='unitPrice'>
            <Input type='number' placeholder='UnitPrice' />
          </Form.Item>
          <Form.Item label='CookingTime' name='cookingTime'>
            <Input type='number' placeholder='cookingTime' />
          </Form.Item>
          <Form.Item label='Priority' name='priority'>
            <Input type='number' placeholder='Priority' />
          </Form.Item>
          <Form.Item label='Description' name='description'>
            <Input placeholder='Description' />
          </Form.Item>
          <Form.Item label='IsEnabled' name='isEnabled' valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

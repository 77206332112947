import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { KittingUser, CreateKittingUserRequest, UpdateKittingUserRequest } from '@/typings/entity/kittingUser';
import { useCreateKittingUserApi, useUpdateKittingUserApi } from '@/api/kitting';
import { Store } from 'antd/lib/form/interface';

export function useKittingListHelper() {
  const editDrawer = useEditDrawerState<KittingUser>('id', [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
  ]);
  const dataSource = useTableDataSource<KittingUser>();

  const createApi = useCreateKittingUserApi();
  const updateApi = useUpdateKittingUserApi();
  const onSubmit = (values: Store) => {
    const { name, password } = values;
    const payload: CreateKittingUserRequest | UpdateKittingUserRequest = {
      name,
    };
    if (password.length > 0) {
      payload.password = password;
    }
    if (editDrawer.mode === 'create') {
      createApi(payload).then((res) => {
        dataSource.push(res);
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(next).then(() => {
        dataSource.replace('id', next);
        editDrawer.close();
      });
    }
  };

  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

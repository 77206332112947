import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { useCreateRestaurantUserApi, useUpdateRestaurantUserApi } from '@/api/brand';
import { RestaurantUser, CreateRestaurantUserRequest } from '@/typings/entity/restaurantUser';

export function useRestaurantUserListHelper(brandId: number, restaurantId: number) {
  const editDrawer = useEditDrawerState<RestaurantUser>('id', [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
  ]);
  const dataSource = useTableDataSource<RestaurantUser>();

  const createApi = useCreateRestaurantUserApi();
  const updateApi = useUpdateRestaurantUserApi();
  const onSubmit = (values: Store) => {
    const { name } = values;
    const payload: CreateRestaurantUserRequest | RestaurantUser = {
      name,
      restaurantId,
    };
    if (values.password) {
      payload.password = values.password;
    }
    if (editDrawer.mode === 'create') {
      createApi(brandId, payload).then((res) => {
        dataSource.push(res);
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(brandId, next).then(() => {
        dataSource.replace('id', next);
        editDrawer.close();
      });
    }
  };

  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

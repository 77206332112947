import { useDispatch } from 'react-redux';
import { authSlice, useAuth } from '@/stores/auth';
import { useHistory } from 'react-router-dom';
import { defaultPaths } from '@/constants';
import { useForm } from 'antd/lib/form/util';
import { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useLoginApi, useMeApi } from '@/api/auth';

export const useLoginState = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuth();
  const loginApi = useLoginApi();
  const meApi = useMeApi();
  const [error, setError] = useState<string>('');
  const [requesting, setRequesting] = useState<boolean>(false);
  const [form] = useForm();
  const logout = () => {
    dispatch(authSlice.actions.logout());
  };
  const onFinish = async (values: Store) => {
    setError('');
    const { name, password } = values;
    setRequesting(true);
    try {
      const res = await loginApi({ name, password });
      setRequesting(false);
      dispatch(authSlice.actions.login(res));
      history.push(defaultPaths.authorized);
    } catch {
      setError('認証情報に誤りがあります');
      setRequesting(false);
    }
  };
  const redirectIfAuthenticated = () => {
    if (!auth.token) {
      return;
    }
    meApi()
      .then(() => {
        history.push(defaultPaths.authorized);
      })
      .catch(() => {
        logout();
      });
  };

  return {
    onFinish,
    form,
    error,
    requesting,
    redirectIfAuthenticated,
  };
};

import { useAxiosClient } from '@/helpers/axios';

export function useCreateUbereatsOrder() {
  const axios = useAxiosClient();
  return async (restaurantId: number) =>
    axios.post<string>(`/test/data/ubereats/${restaurantId}`).then((res) => res.data);
}

export function useCreateMenuOrder() {
  const axios = useAxiosClient();
  return async (restaurantId: number) => axios.post<string>(`/test/data/menu/${restaurantId}`).then((res) => res.data);
}

export function useCreateDemaecanOrder() {
  const axios = useAxiosClient();
  return async (restaurantId: number) =>
    axios.post<string>(`/test/data/demaecan/${restaurantId}`).then((res) => res.data);
}

import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth } from '@/stores/auth';

export function useAxiosClient() {
  const auth = useAuth();
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:4002',
  });
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const { token } = auth;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err: AxiosError) => {
      return Promise.reject(err);
    },
  );
  axios.interceptors.response.use(
    (res: AxiosResponse) => {
      if (res.data.errors) {
        return Promise.reject(res);
      }
      return Promise.resolve(res);
    },
    (err: AxiosError) => Promise.reject(err),
  );
  return axios;
}

import React, { useState } from 'react';
import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { useCreateRestaurantTemporaryOpeningTimesApi, useDeleteRestaurantTemporaryOpeningTimesApi } from '@/api/brand';
import {
  RestaurantTemporaryOpeningTime,
  CreateRestaurantTemporaryOpeningTimeRequest,
} from '@/typings/entity/restaurantTemporaryOpeningTime';
import { Button } from 'antd';

type OpeningTimeTableRow = RestaurantTemporaryOpeningTime;

export function useTemporaryOpeningTimeHelper(brandId: number, restaurantId: number) {
  let reload: () => void;
  const [openingTimes, setOpeningTimes] = useState<RestaurantTemporaryOpeningTime | undefined>();
  const dataSource = useTableDataSource<OpeningTimeTableRow>();
  const deleteApi = useDeleteRestaurantTemporaryOpeningTimesApi();
  const handleDeleteOpeningTimes = (value: RestaurantTemporaryOpeningTime) => {
    deleteApi(brandId, value.restaurantId, value.date, value.startAt).then(() => {
      reload();
    });
  };
  const editDrawer = useEditDrawerState<OpeningTimeTableRow>(
    'date',
    [
      {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      },
      {
        title: 'StartAt',
        dataIndex: 'startAt',
      },
      {
        title: 'EndAt',
        dataIndex: 'endAt',
      },
    ],
    (value: RestaurantTemporaryOpeningTime) => (
      <>
        <Button onClick={() => handleDeleteOpeningTimes(value)}>削除</Button>
      </>
    ),
    true,
  );

  const createApi = useCreateRestaurantTemporaryOpeningTimesApi();
  const onSubmit = (values: Store) => {
    const { datePicker, startAtPicker, endAtPicker } = values;
    const date = datePicker.format('YYYY-MM-DD');
    const startAt = startAtPicker.format('HH:mm');
    const endAt = endAtPicker.format('HH:mm');
    const payload: CreateRestaurantTemporaryOpeningTimeRequest | RestaurantTemporaryOpeningTime = {
      date,
      startAt,
      endAt,
    };
    createApi(brandId, restaurantId, payload).then(() => {
      reload();
      editDrawer.close();
    });
  };

  const setReload = (func: () => void) => {
    reload = func;
  };

  return {
    setOpeningTimes,
    openingTimes,
    dataSource,
    onSubmit,
    editDrawer,
    setReload,
  };
}

import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { useCreateItemApi, useUpdateItemApi } from '@/api/brand';
import { Item, CreateItemRequest } from '@/typings/entity/item';

export function useItemListHelper(brandId: number, categoryId: number) {
  const editDrawer = useEditDrawerState<Item>('id', [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: 'UnitPrice',
      dataIndex: 'unitPrice',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
    },
  ]);
  const dataSource = useTableDataSource<Item>();

  const createApi = useCreateItemApi();
  const updateApi = useUpdateItemApi();
  const onSubmit = (values: Store) => {
    const { name, unitPrice, priority, cookingTime, description, isEnabled } = values;
    const payload: CreateItemRequest | Item = {
      name,
      categoryId,
      description,
      unitPrice: parseInt(unitPrice, 10),
      priority: parseInt(priority, 10),
      cookingTime: parseInt(cookingTime, 10),
      isEnabled: isEnabled as boolean,
    };
    if (editDrawer.mode === 'create') {
      createApi(brandId, payload).then((res) => {
        dataSource.push(res);
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(brandId, next).then(() => {
        dataSource.replace('id', next);
        editDrawer.close();
      });
    }
  };

  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

import React from 'react';
import { DrawerState } from '@/helpers/hooks';
import { Drawer } from 'antd';

interface Props {
  title: string;
  state: DrawerState;
}

export const FormDrawer: React.FC<Props> = ({ title, state, children }) => {
  return (
    <Drawer title={title} width={720} onClose={state.close} visible={state.isOpen} bodyStyle={{ paddingBottom: 80 }}>
      {children}
    </Drawer>
  );
};

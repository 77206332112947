import React from 'react';
import { Button, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import {
  CreateRestaurantExternalMediumRequest,
  UpdateRestaurantExternalMediumRequest,
  ExternalMedia,
} from '@/typings/entity/externalMedia';
import {
  useActivateUbereatsApi,
  useCreateRestaurantExternalMediumApi,
  useUpdateRestaurantExternalMediumApi,
} from '@/api/brand';

export function useRestaurantExternalMediaListHelper(brandId: number, restaurantId: number) {
  const dataSource = useTableDataSource<ExternalMedia>();
  const createApi = useCreateRestaurantExternalMediumApi();
  const updateApi = useUpdateRestaurantExternalMediumApi();
  const ubereatsActivateApi = useActivateUbereatsApi();
  const handleClickUbereatsEnabled = (payload: ExternalMedia) => {
    const info = Modal.info({
      title: 'running',
      maskClosable: false,
      okButtonProps: {
        disabled: true,
      },
      cancelButtonProps: {
        disabled: true,
      },
      content: (
        <div>
          <p>Running...</p>
        </div>
      ),
    });
    ubereatsActivateApi(brandId, payload)
      .then((res) => {
        info.update({
          type: 'success',
          title: 'activate',
          maskClosable: true,
          okButtonProps: {
            disabled: false,
          },
          cancelButtonProps: {
            disabled: false,
          },
          content: (
            <div>
              <p>activated</p>
            </div>
          ),
        });
        dataSource.replace('id', {
          ...payload,
          ubereatsPosStatus: res,
        });
      })
      .catch(() => {
        info.update({
          type: 'error',
          title: 'failed',
          maskClosable: true,
          okButtonProps: {
            disabled: false,
          },
          cancelButtonProps: {
            disabled: false,
          },
          content: (
            <div>
              <p>failed</p>
            </div>
          ),
        });
      });
  };
  const editDrawer = useEditDrawerState<ExternalMedia>(
    'id',
    [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
      },
      {
        title: 'Ubereats Store ID',
        dataIndex: 'ubereatsStoreId',
        key: 'ubereatsStoreId',
      },
      {
        title: 'Demaecan Store ID',
        dataIndex: 'demaecanStoreId',
        key: 'demaecanStoreId',
      },
      {
        title: 'Menu Store ID',
        dataIndex: 'menuStoreId',
        key: 'menuStoreId',
      },
      {
        title: 'Ubereats Restaurant Name',
        dataIndex: 'ubereatsRestaurantName',
        key: 'ubereatsRestaurantName',
      },
      {
        title: 'Ubereats Status',
        dataIndex: 'ubereatsPosStatus',
        key: 'ubereatsPosStatus',
        render: (value) => (value ? '有効' : ''),
      },
    ],
    (value: ExternalMedia) => (
      <>
        <Button onClick={() => handleClickUbereatsEnabled(value)} disabled={value.ubereatsPosStatus}>
          Ubereats有効化
        </Button>
      </>
    ),
  );
  const onSubmit = (values: Store) => {
    const { name, ubereatsStoreId, demaecanStoreId, menuStoreId } = values;
    const payload: CreateRestaurantExternalMediumRequest | UpdateRestaurantExternalMediumRequest = {
      restaurantId,
      name,
      ubereatsStoreId,
      demaecanStoreId,
      menuStoreId,
    };
    if (editDrawer.mode === 'create') {
      createApi(brandId, payload).then((res) => {
        dataSource.push({
          ...res,
        });
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(brandId, next).then((res) => {
        dataSource.replace('id', {
          ...res,
        });
        editDrawer.close();
      });
    }
  };
  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useFetchCompanyBrandsApi } from '@/api/company';
import { useParams } from 'react-router-dom';
import { useCompanyBrandListHelper } from './helper';

export const CompanyBrandList: React.FC = () => {
  const params = useParams<{ id: string }>();
  const companyId = parseInt(params.id, 10);
  const state = useCompanyBrandListHelper(companyId);
  const fetchBrands = useFetchCompanyBrandsApi();
  React.useEffect(() => {
    fetchBrands(companyId).then((res) => {
      state.dataSource.setList(res.map((r) => ({ ...r, key: r.id })));
    });
    // eslint-disable-next-line
  }, [companyId]);
  return (
    <AdminLayout>
      <h1>ブランド</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='Brand' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Slug' name='slug'>
            <Input placeholder='Slug' />
          </Form.Item>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

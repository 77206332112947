import React from 'react';
import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { Brand, CreateBrandRequest } from '@/typings/entity/brand';
import { useCreateBrandApi, useUpdateBrandApi } from '@/api/brand';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';

export function useCompanyBrandListHelper(companyId: number) {
  const history = useHistory();
  const handleClickCategories = (value: Brand) => {
    history.push(`/brands/${value.id}/categories`);
  };
  const editDrawer = useEditDrawerState<Brand>(
    'id',
    [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (_: string, record: Brand) => <Link to={`/brands/${record.id}/restaurants`}>{record.name}</Link>,
      },
    ],
    (value: Brand) => <Button onClick={() => handleClickCategories(value)}>商品カテゴリ</Button>,
  );
  const dataSource = useTableDataSource<Brand>();

  const createApi = useCreateBrandApi();
  const updateApi = useUpdateBrandApi();
  const onSubmit = (values: Store) => {
    const { name, slug } = values;
    const payload: CreateBrandRequest | Brand = {
      name,
      slug,
      companyId,
    };
    if (editDrawer.mode === 'create') {
      createApi(payload).then((res) => {
        dataSource.push(res);
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(next).then(() => {
        dataSource.replace('id', next);
        editDrawer.close();
      });
    }
  };

  return {
    dataSource,
    onSubmit,
    editDrawer,
  };
}

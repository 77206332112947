import { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
};

export const title: CSSProperties = {
  margin: 10,
};
export const button: CSSProperties = {
  margin: 10,
};

import { useAxiosClient } from '@/helpers/axios';
import { LoginRequest, LoginResponse } from '@/typings/http/auth';
import { AdminUser } from '@/typings/entity/adminUser';

export function useLoginApi() {
  const axios = useAxiosClient();
  return async (payload: LoginRequest) => axios.post<LoginResponse>('/login', payload).then((res) => res.data);
}

export function useMeApi() {
  const axios = useAxiosClient();
  return async () => axios.get<AdminUser>('/me').then((res) => res.data);
}

import React, { useState } from 'react';
import { useEditDrawerState, useTableDataSource } from '@/helpers/hooks';
import { Store } from 'antd/lib/form/interface';
import { useCreateRestaurantApi, useUpdateRestaurantApi } from '@/api/brand';
import { useHistory } from 'react-router-dom';
import { Restaurant, CreateRestaurantRequest } from '@/typings/entity/restaurant';
import { Button } from 'antd';
import { Brand } from '@/typings/entity/brand';

type RestaurantTableRow = Restaurant & {
  url: string;
};

export function makeRestaurantUrl(brand: Brand, restaurant: Restaurant) {
  const baseUrl = process.env.REACT_APP_TAKEOUT_BASE_URL || 'http://localhost:3000';
  return `${baseUrl}/${brand.slug}/${restaurant.id}`;
}

export function useRestaurantListHelper(brandId: number) {
  const history = useHistory();
  const [brand, setBrand] = useState<Brand | undefined>();
  const handleClickShowUsers = (value: Restaurant) => {
    history.push(`/brands/${value.brandId}/restaurants/${value.id}/users`);
  };
  const handleClickOpeningTimes = (value: Restaurant) => {
    history.push(`/brands/${value.brandId}/restaurants/${value.id}/opening-times`);
  };
  const handleClickExternalMedia = (value: Restaurant) => {
    history.push(`/brands/${value.brandId}/restaurants/${value.id}/external-media`);
  };
  const editDrawer = useEditDrawerState<RestaurantTableRow>(
    'id',
    [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
      },
      {
        title: 'Tel',
        dataIndex: 'tel',
      },
      {
        title: 'Address',
        dataIndex: 'address',
      },
      {
        title: 'URL',
        dataIndex: 'url',
        render: (text) => (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={text} target='_blank'>
            {text}
          </a>
        ),
      },
    ],
    (value: Restaurant) => (
      <>
        <Button onClick={() => handleClickShowUsers(value)}>アカウント</Button>
        <Button onClick={() => handleClickOpeningTimes(value)}>営業時間</Button>
        <Button onClick={() => handleClickExternalMedia(value)}>メディア連携</Button>
      </>
    ),
  );
  const dataSource = useTableDataSource<RestaurantTableRow>();

  const createApi = useCreateRestaurantApi();
  const updateApi = useUpdateRestaurantApi();
  const onSubmit = (values: Store) => {
    const { name, tel, address, mapUrl } = values;
    const payload: CreateRestaurantRequest | Restaurant = {
      name,
      brandId,
      tel,
      address,
      mapUrl,
    };
    if (editDrawer.mode === 'create') {
      createApi(payload).then((res) => {
        dataSource.push({
          ...res,
          url: makeRestaurantUrl(brand as Brand, res),
        });
        editDrawer.close();
      });
    } else {
      // edit
      const next = {
        ...payload,
        id: editDrawer.identifier as number,
      };
      updateApi(next).then(() => {
        dataSource.replace('id', {
          ...next,
          url: makeRestaurantUrl(brand as Brand, next),
        });
        editDrawer.close();
      });
    }
  };

  return {
    setBrand,
    dataSource,
    onSubmit,
    editDrawer,
  };
}

import React from 'react';
import { Tooltip } from 'antd';
import { CheckCircleTwoTone, ExclamationCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/lib/table';
import { ColumnsType, Key, SorterResult } from 'antd/lib/table/interface';
import { useFetchPrinterConnectedStatusListApi } from '@/api/printers';
import { Brand } from '@/typings/entity/brand';

type Address = {
  url: string;
  text: string;
};

export type RowData = {
  lastConnectedAt: string;
  prefecture: string;
  address: Address;
  brandName: string;
  restaurantName: string;
  tel: string;
  printerMaker: number;
  brandId: number;
  restaurantId: number;
};

type LastStatus = 'ok' | 'ng' | 'warn';

function calcLastStatus(lastConnectedAt: string): LastStatus {
  const diffMsec = new Date().getTime() - new Date(lastConnectedAt).getTime();
  if (diffMsec >= 24 * 60 * 60 * 1000) {
    return 'ng';
  }
  if (diffMsec >= 30 * 1000) {
    return 'warn';
  }
  return 'ok';
}

function makeGCPUrl(restaurantId: number, row: RowData): string {
  if (row.printerMaker === 0) {
    return `https://console.cloud.google.com/logs/query;query=protoPayload.resource%3D~%22%2Fprinting%2Fepson%2F${restaurantId}%2Fcancels%22;timeRange=P7D?hl=ja&project=mo-admin-prd&query=%0A`;
  }
  return `https://console.cloud.google.com/logs/query;query=protoPayload.resource%3D~%22%2Fprinting%2Fstar%2F${restaurantId}%22;timeRange=P7D?hl=ja&project=mo-admin-prd&query=%0A`;
}

function makeColumns(brands: Brand[]): ColumnsType<RowData> {
  return [
    {
      title: '印刷',
      dataIndex: 'lastConnectedAt',
      filterMultiple: false,
      defaultFilteredValue: ['0'],
      filters: [
        { text: 'ALL', value: 0 },
        { text: 'OK', value: 1 },
        { text: 'WARN', value: 2 },
        { text: 'NG', value: 3 },
      ],
      render: (lastConnectedAt: string) => {
        const status = calcLastStatus(lastConnectedAt);
        if (status === 'ng') {
          return (
            <Tooltip title={`${lastConnectedAt}`}>
              <CloseCircleTwoTone twoToneColor='#cf1322' />
            </Tooltip>
          );
        }
        if (status === 'warn') {
          return (
            <Tooltip title={`${lastConnectedAt}`}>
              <ExclamationCircleTwoTone twoToneColor='#d48806' />
            </Tooltip>
          );
        }
        return (
          <Tooltip title={`${lastConnectedAt}`}>
            <CheckCircleTwoTone twoToneColor='#096dd9' />
          </Tooltip>
        );
      },
    },
    {
      title: '都道府県',
      dataIndex: 'prefecture',
      filters: [
        { text: '東京都', value: '東京都' },
        { text: '関東6県', value: '関東6県' },
        { text: '関東以外', value: '関東以外' },
      ],
    },
    {
      title: '住所',
      dataIndex: 'address',
      render: (address) => (
        <a href={address.url} target='_blank' rel='noopener noreferrer'>
          {address.text}
        </a>
      ),
    },
    {
      title: 'ブランド',
      dataIndex: 'brandId',
      filters: brands.map((b) => ({
        text: b.name,
        value: b.id,
      })),
      render: (brandId) => {
        const found = brands.find((b) => b.id === brandId);
        if (!found) {
          return null;
        }
        return <span>{found.name}</span>;
      },
    },
    {
      title: '店舗名',
      dataIndex: 'restaurantName',
    },
    // {
    //   title: 'IVR最終架電時刻',
    //   dataIndex: '', // TODO: 入れるデータを確認して入れる
    // },
    {
      title: '電話番号',
      dataIndex: 'tel',
    },
    {
      title: 'GCP',
      dataIndex: 'restaurantId',
      sorter: (a, b): number => a.restaurantId - b.restaurantId,
      render: (restaurantId, row) => {
        const href = makeGCPUrl(restaurantId, row);
        return (
          <a href={href} target='_blank' rel='noopener noreferrer'>
            {restaurantId}
          </a>
        );
      },
    },
  ];
}

type Pagination = {
  current: number;
  pageSize: number;
  total: number;
};

type Filter = {
  field: string;
  values: string[];
};

type Sorter = {
  field: string;
  order: string;
};

type FetchResult = {
  totalDataCount: number;
  currentPage: number;
  pageSize: number;
  brands: Brand[];
  data: RowData[];
};

const paginationBase = {
  showSizeChanger: true,
  pageSizeOptions: ['5', '20', '50', '100'],
};

function useFetchApi() {
  const fetchPrinterConnectedStatusListApi = useFetchPrinterConnectedStatusListApi();

  return async (pageSize: number, targetPage: number, filters: Filter[], sorters: Sorter[]): Promise<FetchResult> => {
    const res = await fetchPrinterConnectedStatusListApi({
      perPage: pageSize,
      page: targetPage,
      filters,
      sorters,
    });

    const data: RowData[] = [];
    res.data.forEach((row) => {
      data.push({
        lastConnectedAt: row.lastConnectedAt,
        prefecture: row.prefecture,
        address: {
          text: row.address,
          url: row.mapUrl,
        },
        brandName: row.brandName,
        restaurantName: row.restaurantName,
        tel: row.tel,
        printerMaker: row.printerMaker,
        restaurantId: row.restaurantId,
        brandId: row.brandId,
      });
    });

    return {
      totalDataCount: res.totalDataCount,
      brands: res.brands,
      currentPage: res.currentPage,
      pageSize: res.perPage,
      data,
    };
  };
}

function parseSorters(sorters: SorterResult<RowData> | SorterResult<RowData>[]): Sorter[] {
  if (!Array.isArray(sorters)) {
    return [
      {
        field: sorters.toString(),
        order: sorters.order === 'descend' ? 'desc' : 'asc',
      },
    ];
  }
  const ss: Sorter[] = [];
  sorters.forEach((s) => {
    if (!s.field) {
      return;
    }
    ss.push({
      field: s.field.toString(),
      order: s.order === 'descend' ? 'desc' : 'asc',
    });
  });
  return ss;
}

function parseFilters(filters: Record<string, Key[] | null>): Filter[] {
  const fs: Filter[] = [];
  Object.keys(filters).forEach((key) => {
    const vs = filters[key];
    if (!vs) {
      return;
    }
    const values: string[] = [];
    vs.forEach((v) => {
      values.push(v.toString());
    });
    fs.push({
      field: key,
      values,
    });
  });
  return fs;
}

export function useHelper() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentPagination, setCurrentPagination] = React.useState<Pagination>({
    current: 1,
    pageSize: 50,
    total: 0,
  });

  const [currentFilters, setCurrentFilters] = React.useState<Filter[]>([]);
  const [currentSorters, setCurrentSorters] = React.useState<Sorter[]>([]);
  const [data, setData] = React.useState<RowData[]>([]);
  const [brands, setBrands] = React.useState<Brand[]>([]);
  const fetchApi = useFetchApi();

  const reload = async (pageSize: number, current: number, filters: Filter[], sorters: Sorter[]) => {
    setIsLoading(true);
    const res = await fetchApi(pageSize, current, filters, sorters);
    console.log({ filters });
    setData(
      res.data.filter((row) => {
        const found = filters.find((f) => f.field === 'lastConnectedAt');
        if (!found || found.values.includes('0')) {
          return true;
        }
        const status = calcLastStatus(row.lastConnectedAt);
        if (found.values.includes('1')) {
          return status === 'ok';
        }
        if (found.values.includes('2')) {
          return status === 'warn';
        }
        return status === 'ng';
      }),
    );
    setBrands(res.brands);
    setIsLoading(false);
    setCurrentPagination({
      current: res.currentPage,
      pageSize: res.pageSize,
      total: res.totalDataCount,
    });
  };

  React.useEffect(() => {
    reload(currentPagination.pageSize, currentPagination.current, currentFilters, currentSorters).catch(console.error);
    // eslint-disable-next-line
  }, []);

  const handleClickReload = async () => {
    reload(currentPagination.pageSize, currentPagination.current, currentFilters, currentSorters).catch(console.error);
  };

  const handleChangeTableProps = async (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorters: SorterResult<RowData> | SorterResult<RowData>[],
  ) => {
    const nextPagination = {
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 50,
      total: pagination.total || 0,
    };
    setCurrentPagination(nextPagination);

    const nextFitlers = parseFilters(filters);
    setCurrentFilters(nextFitlers);

    const nextSorters = parseSorters(sorters);
    setCurrentSorters(nextSorters);

    reload(nextPagination.pageSize, nextPagination.current, nextFitlers, nextSorters).catch(console.error);
  };

  const columns = React.useMemo(() => makeColumns(brands), [brands]);

  const pagination = React.useMemo(
    () => ({
      ...paginationBase,
      ...currentPagination,
    }),
    [currentPagination],
  );
  return {
    handleClickReload,
    handleChangeTableProps,
    columns,
    data,
    isLoading,
    pagination,
  };
}

import { useAxiosClient } from '@/helpers/axios';
import { CreateKittingUserRequest, KittingUser, UpdateKittingUserRequest } from '@/typings/entity/kittingUser';

export function useFetchKittingUsersApi() {
  const axios = useAxiosClient();
  return async () => axios.get<KittingUser[]>('/kitting/users').then((res) => res.data);
}

export function useCreateKittingUserApi() {
  const axios = useAxiosClient();
  return async (params: CreateKittingUserRequest) =>
    axios.post<KittingUser>('/kitting/users', params).then((res) => res.data);
}

export function useUpdateKittingUserApi() {
  const axios = useAxiosClient();
  return async (params: UpdateKittingUserRequest) =>
    axios.put<KittingUser>(`/kitting/users/${params.id}`, params).then((res) => res.data);
}

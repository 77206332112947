import { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  minWidth: '980px',
};

export const sider: CSSProperties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
};

export const title: CSSProperties = {
  margin: 0,
  fontSize: 24,
  padding: 20,
  color: '#f3f3f3',
  backgroundColor: '#1e2d3c',
};

export const main: CSSProperties = {
  marginLeft: 200,
  backgroundColor: '#fff',
};

export const content: CSSProperties = {
  margin: 20,
  padding: 20,
  backgroundColor: '#f3f4f7',
};

import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input, InputNumber } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams } from 'react-router-dom';
import { useFetchRestaurantExternalMediaApi } from '@/api/brand';
import { useRestaurantExternalMediaListHelper } from '@/pages/RestaurantExternalMediaList/helper';

export const RestaurantExternalMediaList = () => {
  const params = useParams<{ id: string; restaurantId: string }>();
  const brandId = parseInt(params.id, 10);
  const restaurantId = parseInt(params.restaurantId, 10);
  const state = useRestaurantExternalMediaListHelper(brandId, restaurantId);
  const fetch = useFetchRestaurantExternalMediaApi();
  React.useEffect(() => {
    fetch(brandId, restaurantId).then((res) => {
      state.dataSource.setList(res.map((r) => ({ ...r, key: r.id })));
    });
    // eslint-disable-next-line
  }, [brandId, restaurantId]);
  return (
    <AdminLayout>
      <h1>メディア連携</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='ExternalMedia' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Brand Name' />
          </Form.Item>
          <Form.Item label='UbereatsStoreId' name='ubereatsStoreId'>
            <Input placeholder='ubereats store id' />
          </Form.Item>
          <Form.Item label='DemaecanStoreId' name='demaecanStoreId'>
            <Input placeholder='demaecan store id' />
          </Form.Item>
          <Form.Item label='MenuStoreId' name='menuStoreId'>
            <InputNumber placeholder='menu store id' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams } from 'react-router-dom';
import { useFetchRestaurantsApi, useFetchBrandApi } from '@/api/brand';
import { useRestaurantListHelper, makeRestaurantUrl } from './helper';

export const RestaurantList: React.FC = () => {
  const params = useParams<{ id: string }>();
  const brandId = parseInt(params.id, 10);
  const state = useRestaurantListHelper(brandId);
  const fetchBrand = useFetchBrandApi();
  const fetchRestaurants = useFetchRestaurantsApi();
  React.useEffect(() => {
    Promise.all([fetchBrand(brandId), fetchRestaurants(brandId)]).then(([brandResponse, restaurantsResponse]) => {
      state.dataSource.setList(
        restaurantsResponse.map((r) => ({ ...r, key: r.id, url: makeRestaurantUrl(brandResponse, r) })),
      );
    });
    // eslint-disable-next-line
  }, [brandId]);

  return (
    <AdminLayout>
      <h1>店舗</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='Restaurant' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Tel' name='tel'>
            <Input placeholder='Tel' />
          </Form.Item>
          <Form.Item label='Address' name='address'>
            <Input placeholder='Address' />
          </Form.Item>
          <Form.Item label='MapURL' name='mapUrl'>
            <Input placeholder='MapURL' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

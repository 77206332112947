import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { useHelper, RowData } from './helper';

const styles = {
  reloadButton: {
    marginTop: 20,
    marginBottom: 20,
  },
};

export const PrinterConnectedStatusList: React.FC = () => {
  const state = useHelper();
  return (
    <AdminLayout>
      <h1>印刷ステータス</h1>
      <Button loading={state.isLoading} onClick={state.handleClickReload} style={styles.reloadButton}>
        <ReloadOutlined />
        再読み込み
      </Button>
      <Table
        columns={state.columns}
        rowKey={(row: RowData) => row.restaurantId}
        dataSource={state.data}
        pagination={state.pagination}
        loading={state.isLoading}
        onChange={state.handleChangeTableProps}
      />
    </AdminLayout>
  );
};

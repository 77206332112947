import React from 'react';
import { Menu } from 'antd';

import { useLocation } from 'react-router-dom';
import { useLinks } from './links';

export const SiderLinks: React.FC = () => {
  const location = useLocation();
  const links = useLinks();
  return (
    <Menu theme='dark' mode='inline' defaultSelectedKeys={[location.pathname]}>
      {links.map((l) => (
        <Menu.Item key={l.route} onClick={l.navigate}>
          {l.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

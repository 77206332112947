import React from 'react';
import { Button, Form, Input } from 'antd';

import { GuestLayout } from '@/components/Layouts/GuestLayout';
import Title from 'antd/lib/typography/Title';
import { useLoginState } from './helper';
import { rules } from './form';
import * as styles from './styles';

export const Login: React.FC = () => {
  const state = useLoginState();
  React.useEffect(() => {
    state.redirectIfAuthenticated();
    // eslint-disable-next-line
  }, []);
  return (
    <GuestLayout>
      <section style={styles.container}>
        <Form {...styles.layout} form={state.form} onFinish={state.onFinish} scrollToFirstError style={styles.form}>
          <Title style={styles.title}>Ordee System Admin</Title>
          <Form.Item label='Username' name='name' rules={rules.username}>
            <Input placeholder='username' />
          </Form.Item>
          <Form.Item label='Passowrd' name='password' rules={rules.password}>
            <Input.Password placeholder='********' />
          </Form.Item>
          {state.error.length > 0 && (
            <Form.Item wrapperCol={styles.submitWrapperCol}>
              <div style={styles.error}>{state.error}</div>
            </Form.Item>
          )}
          <Form.Item wrapperCol={styles.submitWrapperCol}>
            <Button type='primary' htmlType='submit' loading={state.requesting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </section>
    </GuestLayout>
  );
};

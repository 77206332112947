import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ScrollTopOnPathChanged } from '@/components/ScrollTopOnPathChanged';
import { PrivateRoute } from '@/components/PrivateRoute';

import { routes } from '@/routes';

export const Router: React.FC = () => (
  <BrowserRouter>
    <ScrollTopOnPathChanged>
      <Switch>
        {routes.map((r) => (r.guest ? <Route key={r.name} {...r} /> : <PrivateRoute key={r.name} {...r} />))}
      </Switch>
    </ScrollTopOnPathChanged>
  </BrowserRouter>
);

import { useAxiosClient } from '@/helpers/axios';
import { AdminUser, CreateAdminUserRequest, UpdateAdminUserRequest } from '@/typings/entity/adminUser';

export function useFetchAdminUsersApi() {
  const axios = useAxiosClient();
  return async () => axios.get<AdminUser[]>('/admin-users').then((res) => res.data);
}

export function useCreateAdminUserApi() {
  const axios = useAxiosClient();
  return async (payload: CreateAdminUserRequest) =>
    axios.post<AdminUser>('/admin-users', payload).then((res) => res.data);
}

export function useUpdateAdminUserApi() {
  const axios = useAxiosClient();
  return async (payload: UpdateAdminUserRequest) =>
    axios.put<void>(`/admin-users/${payload.id}`, payload).then((res) => res.data);
}

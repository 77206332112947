import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '@/stores/auth';
import { defaultPaths } from '@/constants';

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const auth = useAuth();
  if (!auth.token) {
    return <Redirect to={{ pathname: defaultPaths.guest }} />;
  }
  return <Route path={rest.path} exact={rest.exact} component={component} />;
};

import { AppRoute } from '@/typings/route';
import { KittingUserList } from '@/pages/KittingUserList';
import { Login } from './pages/Login';
import { Top } from './pages/Top';
import { NotFound } from './pages/NotFound';
import { AdminUserList } from './pages/AdminUserList';
import { CompanyList } from './pages/CompanyList';
import { CompanyBrandList } from './pages/CompanyBrandList';
import { RestaurantList } from './pages/RestaurantList';
import { CategoryList } from './pages/CategoryList';
import { ItemList } from './pages/ItemList';
import { RestaurantUserList } from './pages/RestaurantUserList';
import { EditOpeningTimes } from './pages/EditOpeningTimes';
import { TemporaryOpeningTimes } from './pages/TemporaryOpeningTimes';
import { RestaurantExternalMediaList } from './pages/RestaurantExternalMediaList';
import { PrinterConnectedStatusList } from './pages/PrinterConnectedStatusList';

export const routes: AppRoute[] = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    exact: true,
    guest: true,
  },
  {
    name: 'top',
    path: '/',
    component: Top,
    exact: true,
  },
  {
    name: 'adminUserList',
    path: '/admin-users',
    component: AdminUserList,
    exact: true,
  },
  {
    name: 'companyList',
    path: '/companies',
    component: CompanyList,
    exact: true,
  },
  {
    name: 'companyBrandList',
    path: '/companies/:id/brands',
    component: CompanyBrandList,
    exact: true,
  },
  {
    name: 'restaurantList',
    path: '/brands/:id/restaurants',
    component: RestaurantList,
    exact: true,
  },
  {
    name: 'restaurantUserList',
    path: '/brands/:id/restaurants/:restaurantId/users',
    component: RestaurantUserList,
    exact: true,
  },
  {
    name: 'restaurantExternalMediaList',
    path: '/brands/:id/restaurants/:restaurantId/external-media',
    component: RestaurantExternalMediaList,
    exact: true,
  },
  {
    name: 'categoryList',
    path: '/brands/:id/categories',
    component: CategoryList,
    exact: true,
  },
  {
    name: 'editOpeningTimes',
    path: '/brands/:id/restaurants/:restaurantId/opening-times',
    component: EditOpeningTimes,
    exact: true,
  },
  {
    name: 'temporaryOpeningTIme',
    path: '/brands/:id/restaurants/:restaurantId/temporary-opening-times',
    component: TemporaryOpeningTimes,
    exact: true,
  },
  {
    name: 'itemList',
    path: '/brands/:id/categories/:categoryId/items',
    component: ItemList,
    exact: true,
  },
  {
    name: 'kittingUserList',
    path: '/kitting/users',
    component: KittingUserList,
    exact: true,
  },
  {
    name: 'printerConnectedStatusList',
    path: '/printers/connected_statuses',
    component: PrinterConnectedStatusList,
    exact: true,
  },
  {
    name: 'notFound',
    component: NotFound,
    guest: true,
  },
];

import { CSSProperties } from 'react';

export const container: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export const title: CSSProperties = {
  marginBottom: 30,
  textAlign: 'center',
  color: '#131313',
};

export const form: CSSProperties = {
  width: 620,
  background: '#f7f7f7',
  padding: 20,
};

export const error: CSSProperties = {
  color: '#bf0000',
  fontSize: 12,
};

export const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const submitWrapperCol = {
  ...layout.wrapperCol,
  offset: 4,
};

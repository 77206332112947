import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Form, Button, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { WeekdayTimeRangePicker } from '@/components/EditOpeningTimes/WeekdayTimeRangePIckers';
import { useFetchRestaurantOpeningTimesApi, useSetRestaurantOpeningTimesApi } from '@/api/brand';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Store } from 'antd/lib/form/interface';
import { SetRestaurantOpeningTimeRequest, RestaurantOpeningTime } from '@/typings/entity/restaurantOpeningTime';
import * as styles from './styles';

// @FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultValues: { [key: string]: any } = {
  weekday0: [],
  weekday1: [],
  weekday2: [],
  weekday3: [],
  weekday4: [],
  weekday5: [],
  weekday6: [],
};

function extractOpeningTime(restaurantId: number, weekday: number, values: Store) {
  const key = `weekday${weekday}`;
  const openingTimes: RestaurantOpeningTime[] = [];
  values[key].forEach((v: [moment.Moment, moment.Moment] | undefined) => {
    if (!v) {
      return;
    }
    openingTimes.push({
      restaurantId,
      weekday,
      startAt: v[0].format('HH:mm'),
      endAt: v[1].format('HH:mm'),
    });
  });
  return openingTimes;
}

export const EditOpeningTimes: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string; restaurantId: string }>();
  const brandId = parseInt(params.id, 10);
  const restaurantId = parseInt(params.restaurantId, 10);
  const [form] = useForm();
  const fetchOpeningTimes = useFetchRestaurantOpeningTimesApi();
  const setOpeningTimes = useSetRestaurantOpeningTimesApi();
  React.useEffect(() => {
    form.resetFields();
    fetchOpeningTimes(brandId, restaurantId).then((res) => {
      // deep copy
      const times = JSON.parse(JSON.stringify(defaultValues));
      res.forEach((r) => {
        const key = `weekday${r.weekday}`;
        times[key].push([moment(r.startAt, 'HH:mm'), moment(r.endAt, 'HH:mm')]);
      });
      form.setFieldsValue(times);
    });
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line
  }, [brandId, restaurantId]);
  const onMoveTemporaryClicked = () => {
    history.push(`/brands/${brandId}/restaurants/${restaurantId}/temporary-opening-times`);
  };
  const onFinish = (values: Store) => {
    const req: SetRestaurantOpeningTimeRequest = {
      openingTimes: [],
    };
    [...Array(7)].forEach((_, weekday) => {
      extractOpeningTime(restaurantId, weekday, values).forEach((ot) => req.openingTimes.push(ot));
    });
    setOpeningTimes(brandId, restaurantId, req)
      .then(() =>
        notification.success({
          message: 'Success!!',
        }),
      )
      .catch(() =>
        notification.error({
          message: 'Failed..',
        }),
      );
  };
  return (
    <AdminLayout>
      <h1 style={styles.title}>営業時間設定</h1>
      <Button onClick={onMoveTemporaryClicked} style={styles.button} danger>
        臨時営業時間設定
      </Button>

      <Form form={form} onFinish={onFinish}>
        <Button htmlType='submit' style={styles.button}>
          更新
        </Button>
        <div style={styles.wrapper}>
          <WeekdayTimeRangePicker name='weekday0' label='日曜' />
          <WeekdayTimeRangePicker name='weekday1' label='月曜' />
          <WeekdayTimeRangePicker name='weekday2' label='火曜' />
          <WeekdayTimeRangePicker name='weekday3' label='水曜' />
          <WeekdayTimeRangePicker name='weekday4' label='木曜' />
          <WeekdayTimeRangePicker name='weekday5' label='金曜' />
          <WeekdayTimeRangePicker name='weekday6' label='土曜' />
        </div>
      </Form>
    </AdminLayout>
  );
};

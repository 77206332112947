import React from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';

import { useMeApi } from '@/api/auth';
import { authSlice } from '@/stores/auth';
import Title from 'antd/lib/typography/Title';
import { SiderLinks } from '@/components/Common/SiderLinks';
import { Link } from 'react-router-dom';
import * as styles from './styles';

const { Content, Sider } = Layout;

export const AdminLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const meApi = useMeApi();
  React.useEffect(() => {
    meApi().catch(() => {
      dispatch(authSlice.actions.logout());
    });
  }, [meApi, dispatch]);
  return (
    <Layout style={styles.wrapper}>
      <Sider style={styles.sider}>
        <Link to='/'>
          <Title style={styles.title}>Ordee</Title>
        </Link>
        <SiderLinks />
      </Sider>
      <Layout style={styles.main}>
        <Content style={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

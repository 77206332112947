import { useAxiosClient } from '@/helpers/axios';
import { Brand, CreateBrandRequest } from '@/typings/entity/brand';
import { CreateCategoryRequest, Category } from '@/typings/entity/category';
import { Restaurant, CreateRestaurantRequest } from '@/typings/entity/restaurant';
import { Item, CreateItemRequest } from '@/typings/entity/item';
import { CreateRestaurantUserRequest, RestaurantUser } from '@/typings/entity/restaurantUser';
import { RestaurantOpeningTime, SetRestaurantOpeningTimeRequest } from '@/typings/entity/restaurantOpeningTime';
import {
  RestaurantTemporaryOpeningTime,
  CreateRestaurantTemporaryOpeningTimeRequest,
} from '@/typings/entity/restaurantTemporaryOpeningTime';
import {
  CreateRestaurantExternalMediumRequest,
  UpdateRestaurantExternalMediumRequest,
  ExternalMedia,
} from '@/typings/entity/externalMedia';

export function useFetchBrandsApi() {
  const axios = useAxiosClient();
  return async () => axios.get<Brand[]>('/brands').then((res) => res.data);
}

export function useFetchBrandApi() {
  const axios = useAxiosClient();
  return async (id: number) => axios.get<Brand>(`/brands/${id}`).then((res) => res.data);
}

export function useCreateBrandApi() {
  const axios = useAxiosClient();
  return async (payload: CreateBrandRequest) => axios.post<Brand>('/brands', payload).then((res) => res.data);
}

export function useUpdateBrandApi() {
  const axios = useAxiosClient();
  return async (payload: Brand) => axios.put<void>(`/brands/${payload.id}`, payload).then((res) => res.data);
}

export function useFetchCategoriesApi() {
  const axios = useAxiosClient();
  return async (id: number) => axios.get<Category[]>(`/brands/${id}/categories`).then((res) => res.data);
}

export function useCreateCategoryApi() {
  const axios = useAxiosClient();
  return async (payload: CreateCategoryRequest) =>
    axios.post<Category>(`/brands/${payload.brandId}/categories`, payload).then((res) => res.data);
}

export function useUpdateCategoryApi() {
  const axios = useAxiosClient();
  return async (payload: Category) =>
    axios.put(`/brands/${payload.brandId}/categories/${payload.id}`, payload).then((res) => res.data);
}

export function useFetchRestaurantsApi() {
  const axios = useAxiosClient();
  return async (id: number) => axios.get<Restaurant[]>(`/brands/${id}/restaurants`).then((res) => res.data);
}

export function useCreateRestaurantApi() {
  const axios = useAxiosClient();
  return async (payload: CreateRestaurantRequest) =>
    axios.post<Restaurant>(`/brands/${payload.brandId}/restaurants`, payload).then((res) => res.data);
}

export function useUpdateRestaurantApi() {
  const axios = useAxiosClient();
  return async (payload: Restaurant) =>
    axios.put<void>(`/brands/${payload.brandId}/restaurants/${payload.id}`, payload).then((res) => res.data);
}

export function useFetchItemsApi() {
  const axios = useAxiosClient();
  return async (id: number) => axios.get<Item[]>(`/brands/${id}/items`).then((res) => res.data);
}

export function useCreateItemApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: CreateItemRequest) =>
    axios.post<Item>(`/brands/${id}/items`, payload).then((res) => res.data);
}

export function useUpdateItemApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: Item) =>
    axios.put<void>(`/brands/${id}/items/${payload.id}`, payload).then((res) => res.data);
}

export function useFetchRestaurantUsersApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number) =>
    axios.get<RestaurantUser[]>(`/brands/${id}/restaurants/${restaurantId}/users`).then((res) => res.data);
}

export function useCreateRestaurantUserApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: CreateRestaurantUserRequest) =>
    axios
      .post<RestaurantUser>(`/brands/${id}/restaurants/${payload.restaurantId}/users`, payload)
      .then((res) => res.data);
}

export function useUpdateRestaurantUserApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: RestaurantUser) =>
    axios
      .put<void>(`/brands/${id}/restaurants/${payload.restaurantId}/users/${payload.id}`, payload)
      .then((res) => res.data);
}

export function useFetchRestaurantOpeningTimesApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number) =>
    axios
      .get<RestaurantOpeningTime[]>(`/brands/${id}/restaurants/${restaurantId}/opening-times`)
      .then((res) => res.data);
}

export function useSetRestaurantOpeningTimesApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number, payload: SetRestaurantOpeningTimeRequest) =>
    axios.put<void>(`/brands/${id}/restaurants/${restaurantId}/opening-times`, payload).then((res) => res.data);
}

export function useFetchRestaurantTemporaryOpeningTimesApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number, year: number, month: number) =>
    axios
      .get<RestaurantTemporaryOpeningTime[]>(`/brands/${id}/restaurants/${restaurantId}/temporary-opening-times`, {
        params: {
          year,
          month,
        },
      })
      .then((res) => res.data);
}

export function useCreateRestaurantTemporaryOpeningTimesApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number, payload: CreateRestaurantTemporaryOpeningTimeRequest) =>
    axios
      .post<void>(`/brands/${id}/restaurants/${restaurantId}/temporary-opening-times`, payload)
      .then((res) => res.data);
}

export function useDeleteRestaurantTemporaryOpeningTimesApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number, date: string, startAt: string) =>
    axios.delete<void>(`/brands/${id}/restaurants/${restaurantId}/temporary-opening-times`, {
      params: {
        date,
        startAt,
      },
    });
}

export function useFetchRestaurantExternalMediaApi() {
  const axios = useAxiosClient();
  return async (id: number, restaurantId: number) =>
    axios.get<ExternalMedia[]>(`/brands/${id}/restaurants/${restaurantId}/external-media`).then((res) => res.data);
}

export function useCreateRestaurantExternalMediumApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: CreateRestaurantExternalMediumRequest) =>
    axios
      .post<ExternalMedia>(`/brands/${id}/restaurants/${payload.restaurantId}/external-media`, payload)
      .then((res) => res.data);
}

export function useUpdateRestaurantExternalMediumApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: UpdateRestaurantExternalMediumRequest) =>
    axios
      .put<ExternalMedia>(`/brands/${id}/restaurants/${payload.restaurantId}/external-media/${payload.id}`, payload)
      .then((res) => res.data);
}

export function useActivateUbereatsApi() {
  const axios = useAxiosClient();
  return async (id: number, payload: ExternalMedia) =>
    axios
      .put<boolean>(`/brands/${id}/restaurants/${payload.restaurantId}/external-media/${payload.id}/activate`)
      .then((res) => res.data);
}

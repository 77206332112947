import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState, AuthState } from '@/typings/state';
import { LoginResponse } from '@/typings/http/auth';

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, value: PayloadAction<LoginResponse>) {
      state.token = value.payload.token;
      state.user = value.payload.user;
    },
    logout(state) {
      state.token = undefined;
      state.user = undefined;
    },
  },
});

export const useAuth = () => {
  return useSelector((state: AppState) => state.auth);
};

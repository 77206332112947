import { CSSProperties } from 'react';

type Styles = {
  [key: string]: CSSProperties;
};

export const styles: Styles = {
  container: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F4F6F9',
  },
};

import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { useFetchAdminUsersApi } from '@/api/adminUsers';
import { Table, Button, Form, Input } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useAdminListHelper } from './helper';

export const AdminUserList: React.FC = () => {
  const state = useAdminListHelper();
  const fetchAdminUserApi = useFetchAdminUsersApi();
  React.useEffect(() => {
    fetchAdminUserApi().then((res) => {
      state.dataSource.setList(res.map((a) => ({ ...a, key: a.id })));
    });
    // eslint-disable-next-line
  }, []);
  return (
    <AdminLayout>
      <h1>管理ユーザー</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='AdminUser' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Password' name='password'>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

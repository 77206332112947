import React, { CSSProperties } from 'react';
import { Form, Card, Space, TimePicker, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { RangePicker } = TimePicker;

interface Props {
  label: string;
  name: string;
}

const card: CSSProperties = {
  margin: 10,
  width: 300,
};

export const WeekdayTimeRangePicker: React.FC<Props> = ({ label, name }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <Card style={card}>
            <h2>{label}</h2>
            <Form.Item>
              <Button type='default' onClick={() => add()} block>
                <PlusOutlined /> Add
              </Button>
            </Form.Item>
            {fields.map((field) => (
              <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align='start'>
                <Form.Item {...field}>
                  <RangePicker picker='time' format='HH:mm' minuteStep={1} />
                </Form.Item>
                <Button type='default' shape='circle' icon={<MinusOutlined />} onClick={() => remove(field.name)} />
              </Space>
            ))}
          </Card>
        );
      }}
    </Form.List>
  );
};

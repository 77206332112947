import React from 'react';
import { Button, Modal, Select } from 'antd';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { useCreateDemaecanOrder, useCreateMenuOrder, useCreateUbereatsOrder } from '@/api/testData';

const targetRestaurantIds: { value: number; label: string }[] = [
  { value: 49, label: '[49] SLACK渋谷店' },
  { value: 126, label: '[126] 渋谷店' },
  { value: 127, label: '[127] 中目黒店' },
  { value: 128, label: '[128] 三鷹店' },
  { value: 129, label: '[129] 中野新橋店' },
  { value: 353, label: '[353] 横浜店' },
];

export const Top: React.FC = () => {
  const createUbereatsOrderApi = useCreateUbereatsOrder();
  const createMenuOrderApi = useCreateMenuOrder();
  const createDemaecanOrderApi = useCreateDemaecanOrder();
  const [targetRestaurantId, setTargetRestaurantId] = React.useState<number>(targetRestaurantIds[0].value);
  const [targetRestaurantName, setTargetRestaurantName] = React.useState<string>(targetRestaurantIds[0].label);
  const handleSetTarget = (value: number) => {
    setTargetRestaurantId(value);
    targetRestaurantIds.map((target) => (target.value === value ? setTargetRestaurantName(target.label) : null));
  };
  const showCompleteModal = (mediaName: string) => {
    Modal.success({
      title: 'success',
      content: (
        <div>
          <p>
            {targetRestaurantName}に{mediaName}テストデータを送信しました。アプリを引っ張って更新してください。
          </p>
          <p>
            ※PUSH通知は来ません。
            <br />
            ※印字はされます。
          </p>
        </div>
      ),
    });
  };
  const handleClickUber = () => {
    createUbereatsOrderApi(targetRestaurantId).then(() => {
      showCompleteModal('ubereats');
    });
  };
  const handleClickMenu = () => {
    createMenuOrderApi(targetRestaurantId).then(() => {
      showCompleteModal('Menu');
    });
  };
  const handleClickDemaecan = () => {
    createDemaecanOrderApi(targetRestaurantId).then(() => {
      showCompleteModal('出前館');
    });
  };
  return (
    <AdminLayout>
      <div>
        <h2>テストデータ作成</h2>
        <div>
          <p>データを追加する店舗を選択してください</p>
          <Select defaultValue={targetRestaurantId} style={{ width: 250, marginBottom: 20 }} onChange={handleSetTarget}>
            {targetRestaurantIds.map(({ value, label }) => (
              <Select.Option value={value} key={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <p style={{ marginRight: '10px' }}>
            <Button type='primary' onClick={handleClickUber}>
              Ubereats
            </Button>
          </p>
          <p style={{ marginRight: '10px' }}>
            <Button type='primary' onClick={handleClickMenu}>
              Menu
            </Button>
          </p>
          <p style={{ marginRight: '10px' }}>
            <Button type='primary' onClick={handleClickDemaecan}>
              出前館
            </Button>
          </p>
        </div>
      </div>
    </AdminLayout>
  );
};

import React, { useState, ReactNode } from 'react';
import { Space, Button } from 'antd';
import { useForm, FormInstance } from 'antd/lib/form/util';
import { ColumnsType } from 'antd/lib/table';

export interface DrawerState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export function useDrawerState(): DrawerState {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return {
    isOpen,
    open,
    close,
  };
}

interface EditDrawerState<T> {
  drawer: DrawerState;
  identifier?: T[keyof T];
  mode: 'create' | 'edit';
  form: FormInstance;
  toCreate: () => void;
  toEdit: (values: T) => void;
  close: () => void;
  columns: ColumnsType<T>;
}

export function useEditDrawerState<T>(
  identifierKey: keyof T,
  columnBase: ColumnsType<T>,
  renderAdditionalActions?: (v: T) => ReactNode,
  disableEdit = false,
): EditDrawerState<T> {
  const [form] = useForm();
  const drawer = useDrawerState();
  const [identifier, setIdentifier] = useState<T[keyof T] | undefined>();
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const toCreate = () => {
    setMode('create');
    form.resetFields();
    drawer.open();
  };
  const toEdit = (values: T) => {
    setMode('edit');
    form.resetFields();
    setIdentifier(values[identifierKey]);
    form.setFieldsValue(values);
    drawer.open();
  };
  const close = () => {
    drawer.close();
  };
  const columns: ColumnsType<T> = columnBase.concat([
    {
      title: 'Action',
      dataIndex: 'action',
      width: 320,
      render: (_: string, record: T) => (
        <Space size='middle'>
          {!disableEdit && <Button onClick={() => toEdit(record)}>編集</Button>}
          {renderAdditionalActions && renderAdditionalActions(record)}
        </Space>
      ),
    },
  ]);

  return {
    drawer,
    form,
    identifier,
    mode,
    toCreate,
    toEdit,
    close,
    columns,
  };
}

export function useTableDataSource<T>() {
  const [list, setList] = useState<T[]>([]);
  const replace = (key: keyof T, value: T) => {
    const next = list.map((r) => (r[key] === value[key] ? { ...r, ...value } : r));
    setList(next);
  };
  const unshift = (value: T) => {
    const next = list.map((l) => l);
    next.unshift(value);
    setList(next);
  };
  const push = (value: T) => {
    const next = list.map((l) => l);
    next.push(value);
    setList(next);
  };
  const drop = (key: keyof T, value: T) => {
    const next = list.filter((l) => l[key] !== value[key]);
    setList(next);
  };
  return {
    list,
    setList,
    replace,
    push,
    unshift,
    drop,
  };
}

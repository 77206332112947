import React from 'react';
import { AdminLayout } from '@/components/Layouts/AdminLayout';
import { Table, Button, Form, Input } from 'antd';
import { FormDrawer } from '@/components/Common/FormDrawer';
import { useParams } from 'react-router-dom';
import { useFetchRestaurantUsersApi } from '@/api/brand';
import { useRestaurantUserListHelper } from './helper';

export const RestaurantUserList: React.FC = () => {
  const params = useParams<{ id: string; restaurantId: string }>();
  const brandId = parseInt(params.id, 10);
  const restaurantId = parseInt(params.restaurantId, 10);
  const state = useRestaurantUserListHelper(brandId, restaurantId);
  const fetch = useFetchRestaurantUsersApi();
  React.useEffect(() => {
    fetch(brandId, restaurantId).then((res) => {
      state.dataSource.setList(res.map((r) => ({ ...r, key: r.id })));
    });
    // eslint-disable-next-line
  }, [brandId]);
  return (
    <AdminLayout>
      <h1>店舗アカウント</h1>
      <p>
        <Button onClick={state.editDrawer.toCreate} type='primary'>
          新規追加
        </Button>
      </p>
      <Table columns={state.editDrawer.columns} dataSource={state.dataSource.list} />
      <FormDrawer title='RestaurantUser' state={state.editDrawer.drawer}>
        <Form form={state.editDrawer.form} onFinish={state.onSubmit}>
          <Form.Item label='Name' name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item label='Password' name='password'>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </FormDrawer>
    </AdminLayout>
  );
};

import { useAxiosClient } from '@/helpers/axios';
import { Company, CreateCompanyRequest } from '@/typings/entity/company';
import { Brand } from '@/typings/entity/brand';

export function useFetchCompaniesApi() {
  const axios = useAxiosClient();
  return async () => axios.get<Company[]>('/companies').then((res) => res.data);
}

export function useCreateCompanyApi() {
  const axios = useAxiosClient();
  return async (payload: CreateCompanyRequest) => axios.post<Company>('/companies', payload).then((res) => res.data);
}

export function useUpdateCompanyApi() {
  const axios = useAxiosClient();
  return async (payload: Company) => axios.put<void>(`/companies/${payload.id}`, payload).then((res) => res.data);
}

export function useFetchCompanyBrandsApi() {
  const axios = useAxiosClient();
  return async (id: number) => axios.get<Brand[]>(`/companies/${id}/brands`).then((res) => res.data);
}

import { useHistory } from 'react-router-dom';

interface Link {
  route: string;
  label: string;
}

const links: Link[] = [
  {
    route: '/',
    label: 'Dashboard',
  },
  {
    route: '/companies',
    label: '企業管理',
  },
  {
    route: '/admin-users',
    label: '管理ユーザー',
  },
  {
    route: '/kitting/users',
    label: 'キッティング',
  },
  {
    route: '/printers/connected_statuses',
    label: '印刷ステータス',
  },
];

export function useLinks() {
  const history = useHistory();
  return links.map((l) => ({
    ...l,
    navigate: () => history.push(l.route),
  }));
}
